import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { CurrentCase } from '../components/home/CriminalCaseSection';
import { UserMCIProperties } from '../interfaces/MCIUserProperties';
import { validEndEvent } from '../utils/timeUtils';

export interface UserState extends CurrentCase {
    isLogin: boolean;
    didApply: boolean;
    user: UserMCIProperties | null;
    savedUserLoaded: boolean;
    routeRedirected: boolean;
    endedEvent: boolean;
}

export const initialState: UserState = {
    isLogin: false,
    didApply: false,
    user: null,
    caseTitle: '',
    downloadLink: '',
    savedUserLoaded: false,
    routeRedirected: true,
    endedEvent: validEndEvent()
};

type Action =
    | { type: 'SET_SAVED_USER_LOADED'; loaded: boolean }
    | { type: 'SET_USER'; user: UserMCIProperties }
    | { type: 'LOGOUT' }
    | { type: 'SET_CURRENT_CRIMINAL_CASE'; caseTitle: string; downloadLink: string }
    | { type: 'SET_ROUTE_REDIRECTED'; routeRedirected: boolean };

export type AppDispatch = Dispatch<Action>;

const AppStateContext = createContext<UserState>(initialState);
const AppDispatchContext = createContext<AppDispatch>(() => null);

const reducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.user,
                isLogin: !!action.user,
                didApply: !!action.user.birth
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                isLogin: false,
                didApply: false
            };
        case 'SET_SAVED_USER_LOADED':
            return {
                ...state,
                savedUserLoaded: action.loaded
            };
        case 'SET_CURRENT_CRIMINAL_CASE':
            return {
                ...state,
                caseTitle: action.caseTitle,
                downloadLink: action.downloadLink
            };
        case 'SET_ROUTE_REDIRECTED':
            return {
                ...state,
                routeRedirected: action.routeRedirected
            };

        default:
            return state;
    }
};

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

export const useAppState = () => {
    const state = useContext(AppStateContext);
    if (!state) {
        throw new Error('Cannot find AppStateContext');
    }
    return state;
};

export const useAppDispatch = () => {
    const dispatch = useContext(AppDispatchContext);
    if (!dispatch) {
        throw new Error('Cannot find AppDispatchContext');
    }
    return dispatch;
};
