import firebase from 'firebase/app';
import { from, mergeMap } from 'rxjs';
import { UserMCIProperties } from '../interfaces/MCIUserProperties';

type BadgekeyList =
    | 'badge'
    | 'complete'
    | 'paragliding'
    | 'smart'
    | 'courage'
    | 'brilliant'
    | 'school'
    | 'celebration'
    | 'flare'
    | 'medal'
    | 'diligent'
    | 'alert'
    | 'stars'
    | 'movie'
    | 'trophy';

const getGiveBadgesFn = (key: BadgekeyList, user: UserMCIProperties) => {
    const { uid, displayName } = user;
    const fFunctions = firebase.functions();

    const mciGivenBadgeFn = fFunctions.httpsCallable('mciGiveBadge');
    const logUserActivityFn = fFunctions.httpsCallable('mciLogUserActivity');

    from(mciGivenBadgeFn({ userId: user?.uid, key }))
        .pipe(
            mergeMap(() => {
                return from(logUserActivityFn({ userId: uid, userName: displayName, key: 'equipment_prepared' }));
            })
        )
        .subscribe({
            error: (err) => console.log(err)
        });
};

const isValidBadge = (key: BadgekeyList, user: UserMCIProperties) => {
    const { badges } = user;
    return badges?.find((badge) => badge.key === key) ? true : false;
};

export { getGiveBadgesFn, isValidBadge };
