export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: 'AIzaSyAzFQKiHOmtVZEUlczXMXM90cWDz2GwOUk',
        authDomain: 'chocobot-36417.firebaseapp.com',
        databaseURL: 'https://chocobot-36417.firebaseio.com',
        projectId: 'chocobot-36417',
        storageBucket: 'chocobot-36417.appspot.com',
        messagingSenderId: '393387105289',
        appId: '1:393387105289:web:4518691fdf0a2abbc66cd8',
        measurementId: 'G-KE17FKH1GH'
    },
    protocol: 'pocl.cobot-i.com',
    fbAppId: '1267490827024717',
};
