import { RouteComponentProps, withRouter } from 'react-router-dom';
import './footer.scss';

const Footer = (props: RouteComponentProps) => {
    const {
        location: { pathname }
    } = props;

    const footerLicense = () => {
        if (pathname === '/') {
            return (
                <div className="base__map">
                    <span className="title">베이스 맵 라이센스</span>
                    <p className="license">
                        City of Newisle v1.2 |<br /> Solo-built modern Minecraft city |<br /> Still in active
                        development! Minecraft Map
                    </p>
                </div>
            );
        } else {
            return (
                <>
                    <div className="logo__box">
                        <h2 className="mci__logo">MCI</h2>
                        <span className="business__name">@MCI 마크코딩 수사본부</span>
                    </div>
                    <div className="base__map">
                        <span className="title">베이스 맵 라이센스</span>
                        <p className="license">
                            City of Newisle v1.2 | Solo-built modern Minecraft city | Still in active development!
                            Minecraft Map
                        </p>
                    </div>
                </>
            );
        }
    };

    return (
        <footer id="footer">
            <div className="footer__inr">
                <div className="company__info">
                    <p className="company__name">(주) 포클</p>
                    <p className="company__number">사업자 등록번호 638 - 88 - 01398</p>
                    <p className="company__address">경기 성남시 수정구 창업로 42, 경기 기업성장센터</p>
                    <span className="copy__rights">2021 Pocl Corp. All Rights Reserved.</span>
                </div>
                <div className={`footer__logo ${pathname === '/' ? 'main' : ''}`}>{footerLicense()}</div>
                <ul className="moer__information">
                    <li>이용약관</li>
                    <li>개인정보 보호정책</li>
                    <li>고객센터</li>
                </ul>
            </div>
        </footer>
    );
};

export default withRouter(Footer);
