import environment from '../environments/environment';

declare global {
    interface Window {
        FB: any;
        fbAsyncInit: any
    }
}

export function initFacebookSDK(): Promise<any> {
    return new Promise((resolve) => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: environment.fbAppId,
                cookie: true,
                xfbml: true,
                version: 'v11.0'
            });
            resolve;
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";

            if (fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            }
        }(document, 'script', 'facebook-jssdk'));    
    });
}

const mciGroupId = '267238901866838';
const testGroupId = '371648281300186'; // TODO: remove. 테스트시 이 아이디로 변경해 사용합니다.

export function postVideoToGroup(videoUrl: string, description: string, callback: Function) {
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    const post = () => {
        let groupId = mciGroupId;
        window.FB.api(`/${groupId}/videos`, 'post', {
            file_url: videoUrl,
            description,
        }, (res: any, err: any) => {
            if (err) {
                console.error(err);
            }
            callback();
        });
    }

    window.FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
            post();
            return;
        }
        window.FB.login(() => {
            post();
        });
    });
}

export function share(url: string) {
    if (process.env.NODE_ENV === 'development') {
        return;
    }
    const share = () => {
        window.FB.ui({ method: 'share', href: url, hashtag: '#MCI마크코딩수사대', display: 'popup' });
    }

    window.FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
            share();
            return;
        }
        window.FB.login(() => {
            share();
        });
    });
}
