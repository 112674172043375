import firebase from 'firebase/app';
import 'firebase/auth';
import { from, Observable, take, tap } from 'rxjs';
import { AppDispatch } from '../context/AppContext';
import { UserMCIProperties } from '../interfaces/MCIUserProperties';

const login = (appDispatch: AppDispatch) => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
        .auth()
        .signInWithPopup(provider)
        .then((result: firebase.auth.UserCredential) => {
            if (!result.user) {
                return;
            }
            const user: UserMCIProperties = {
                displayName: result.user.displayName || '',
                uid: result.user.uid
            };
            appDispatch({ type: 'SET_USER', user });
            setUserInfo(user.uid, appDispatch).subscribe();
        })
        .catch((err) => {
            console.error(err);
        });
};

const setUserInfo = (userId: string, appStateDispatch: AppDispatch): Observable<any> => {
    const db = firebase.firestore();
    return from(db.doc(`users/${userId}`).get()).pipe(
        take(1),
        tap((docSnapshot: firebase.firestore.DocumentSnapshot) => {
            const data = docSnapshot.data();
            if (!docSnapshot.exists || !data) {
                appStateDispatch({
                    type: 'SET_USER',
                    user: {
                        displayName: '',
                        uid: userId
                    }
                });
                return;
            }
            appStateDispatch({
                type: 'SET_USER',
                user: data.mci_properties
            });
        })
    );
};

export default { login, setUserInfo };
