import moment from 'moment';

const END_EVENT_DATE = '20211004';

const validEndEvent = (): boolean => {
    const today = moment().format(`YYYYMMDD`);
    return moment(END_EVENT_DATE).isSameOrBefore(today);
};

export { validEndEvent };
