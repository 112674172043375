import CriminalCaseSection from '../components/home/CriminalCaseSection';
import CriminalsSection from '../components/home/CriminalsSection';
import HeadquartersSection from '../components/home/HeadquartersSection';
import MainSection from '../components/home/MainSection';
import '../components/home/home.scss';
import { useEffect, useState, useRef } from 'react';
import AlertMessageForm from '../components/common/alert-message/AlertMessageForm';
import { CurSizeWithRouter } from '../App';
import { resetLocation } from '../utils/redirectUtils';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { useAppState } from '../context/AppContext';
import EndEventPopUp from '../components/common/popup/EndEventPopup';

export const wheelEventForPreventDefault = (event: Event) => event.preventDefault();

let curPageNum = 1;
export const HEADER_HIDDEN = 'hidden';

const Home = (props: CurSizeWithRouter) => {
    const { location } = props;
    const [locationMessage, setLocationMessage] = useState('');
    const mainRef = useRef<HTMLElement>(null);
    const { endedEvent } = useAppState();

    const handleWheel = (event: any, mainEl: any) => {
        const { deltaY } = event;
        const sectionHeight = window.innerHeight;
        const lastPage = 5;

        if (deltaY < 0) {
            if (curPageNum === 1) {
                return;
            }
            curPageNum = curPageNum - 1;
        } else if (deltaY > 0) {
            if (curPageNum === lastPage) {
                return;
            }
            curPageNum = curPageNum + 1;
        }

        window.scrollTo({ top: sectionHeight * (curPageNum - 1), behavior: 'smooth' });

        setHeaderClassName(curPageNum, mainEl.previousSibling);
    };

    useEffect(() => {
        if (!mainRef || !mainRef.current) {
            return;
        }
        const mainEl = mainRef.current;

        mainEl.addEventListener('wheel', wheelEventForPreventDefault, { passive: false });

        const wheelEvent = fromEvent(mainEl, 'wheel').pipe(throttleTime(600));
        const eventSubscribe = wheelEvent.subscribe((event) => handleWheel(event, mainEl));

        return () => {
            mainEl.removeEventListener('wheel', wheelEventForPreventDefault);
            eventSubscribe.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!location.state || !location.state.message) {
            return;
        }

        setLocationMessage(location.state.message);
        resetLocation(props);
    }, []);

    const handleAlertClose = () => {
        resetLocation(props);
        setLocationMessage('');
    };

    const setHeaderClassName = (curPageNumber: number, headerEl: any) => {
        if (!mainRef || !mainRef.current) {
            return;
        }

        if (endedEvent) {
            return headerEl.classList.remove(HEADER_HIDDEN);
        }

        if (curPageNumber === 1) {
            headerEl.classList.add(HEADER_HIDDEN);
        } else {
            headerEl.classList.remove(HEADER_HIDDEN);
        }
    };

    return (
        <main className="mci__home" ref={mainRef}>
            {endedEvent && <EndEventPopUp />}
            <AlertMessageForm alertType={locationMessage} onClose={handleAlertClose} />
            <MainSection />
            <CriminalCaseSection {...props} />
            <CriminalsSection {...props} />
            <HeadquartersSection {...props} />
            <div className="detective__test__box">
                <a
                    href="https://xn--cw4by6x.com/MCI%EB%A7%88%ED%81%AC%EC%BD%94%EB%94%A9%EC%88%98%EC%82%AC%EB%8C%80"
                    target="_blank"
                >
                    <div className="test__icon" />
                    <div className="test__txt">탐정 유형 테스트</div>
                </a>
            </div>
        </main>
    );
};

export default Home;
