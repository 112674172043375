import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useAppDispatch, useAppState } from '../../context/AppContext';
import Button from '../common/button/MciButton';
import './header.scss';
import firebase from 'firebase/app';
import 'firebase/auth';
import authUtils from '../../utils/auth.utils';
import { useEffect, useState } from 'react';
import { HEADER_HIDDEN } from '../../router/Home';

const Header: React.FC<RouteComponentProps> = (props) => {
    const pathName = props.location.pathname;
    const appState = useAppState();
    const appStateDispatch = useAppDispatch();
    const [headerHidden, setHeaderHidden] = useState(true);

    const { isLogin, endedEvent } = appState;

    const logout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                appStateDispatch({ type: 'LOGOUT' });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        pathName === '/' ? setHeaderHidden(true) : setHeaderHidden(false);
    }, [pathName]);

    const getHeaderClassName = () => {
        let headerClassName = 'mci_header';

        if (endedEvent) {
            return headerClassName;
        }

        if (pathName !== '/') {
            return headerClassName;
        } else {
            return `${headerClassName} ${HEADER_HIDDEN}`;
        }
    };
    // 이벤트 종료_ 임시로 로그인/로그아웃 버튼 없앰
    // const headerBtn = () => {
    //     if (pathName === '/' || pathName === '/notes') {
    //         if (isLogin) {
    //             return <Button className="btn_logout" label="페이스북 로그아웃" color="black" onClick={logout} />;
    //         } else {
    //             return (
    //                 <Button
    //                     className="btn_login"
    //                     label="페이스북 로그인"
    //                     color="blue"
    //                     onClick={() => authUtils.login(appStateDispatch)}
    //                 />
    //             );
    //         }
    //     }
    // };

    const headerLinkBtn = () => {
        if (pathName === '/') {
            return (
                <a
                    href="https://shadow-dart-ef9.notion.site/FAQ-a2accd3ec03c45f297649b353a82231e"
                    className="move__to__question"
                    target="_blank"
                />
            );
        } else {
            return (
                <>
                    <Link to="/" className="move__to__home" />
                    <a
                        href="https://shadow-dart-ef9.notion.site/FAQ-a2accd3ec03c45f297649b353a82231e"
                        className="move__to__question"
                        target="_blank"
                    />
                </>
            );
        }
    };

    return (
        <header className={getHeaderClassName()}>
            <div className="link__box">{headerLinkBtn()}</div>
            <h1 className="mci__logo">MCI</h1>
            {/* {headerBtn()} */}
        </header>
    );
};

export default withRouter(Header);
