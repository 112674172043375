import './home.scss';
interface SectionProps {
    id: string;
    children: JSX.Element[] | JSX.Element;
}

const SectionForm = (props: SectionProps) => {
    const { id, children } = props;

    return (
        <section id={id}>
            <div className="sec__contents">{children}</div>
            <p className="scroll__icon">Scroll down!</p>
        </section>
    );
};

export default SectionForm;
