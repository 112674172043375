import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { MouseEventHandler, useState } from 'react';
import { VideoControlsState } from './PopUpVideo';

interface VideoControlProps {
    videoControl: VideoControlsState;
    onPlayPause: MouseEventHandler;
    elapsedTime: string;
    totalDuration: string;
    onVolumeChange: any;
    onSoundSetting: any;
    onToggleFullScreen: any;
    onSeek: any;
    onSeekMouseDown: any;
    onSeekMouseUp: any;
}

const VideoControls = (props: VideoControlProps) => {
    const {
        videoControl,
        onPlayPause,
        elapsedTime,
        totalDuration,
        onSoundSetting,
        onVolumeChange,
        onToggleFullScreen,
        onSeek,
        onSeekMouseDown,
        onSeekMouseUp
    } = props;
    const { playing, played, fullscreen, muted, volume } = videoControl;
    const [soundSetting, setSoundSetting] = useState(false);

    const handleMouseOver = () => {
        return !muted ? setSoundSetting(true) : setSoundSetting(false);
    };

    const handleMouseOut = () => {
        return setSoundSetting(false);
    };

    const setSoundClassName = () => {
        if (muted) {
            return `sound off`;
        }

        return soundSetting ? `sound on show_set` : `sound on`;
    };

    return (
        <div className="control__box">
            <div className="controls">
                <div className="box__left">
                    <div className={playing ? 'pause' : 'play'} onClick={onPlayPause} />
                    <p className="paly_time">{`${elapsedTime} / ${totalDuration}`}</p>
                </div>
                <div className="box__right">
                    <div className={setSoundClassName()} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        <VolumSlider
                            min={0}
                            max={100}
                            value={volume * 100}
                            onChange={onVolumeChange}
                            onChangeCommitted={onVolumeChange}
                        />
                        <span className="sound_icon" onClick={onSoundSetting} />
                    </div>

                    <div className={`full_screen${fullscreen ? '_exit' : ''}`} onClick={onToggleFullScreen} />
                </div>
            </div>
            <PrettoSlider
                min={0}
                max={100}
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
            />
        </div>
    );
};

export default VideoControls;

const PrettoSlider = withStyles({
    root: {
        color: '#FFFFFF50',
        height: 5,
        padding: 0
    },
    thumb: {
        display: 'none'
    },
    track: {
        height: 5,
        borderRadius: 0,
        backgroundColor: '#FF6301',
        cursor: 'pointer'
    },
    rail: {
        height: 5,
        borderRadius: 0
    }
})(Slider);

const VolumSlider = withStyles({
    root: { height: 4, width: 50, color: '#ffffff80', visibility: 'hidden' },
    thumb: {
        display: 'none'
    },
    track: {
        height: 4,
        borderRadius: 3,
        backgroundColor: '#ffffff'
    },
    rail: {
        height: 4,
        borderRadius: 3
    }
})(Slider);
