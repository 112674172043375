import { useEffect, useRef } from 'react';
import { CurSizeWithRouter } from '../../App';
import MciButton from '../common/button/MciButton';
import SectionForm from './SectionForm';

const HeadquartersSection = (props: CurSizeWithRouter) => {
    const id = 'home__headquarters';
    const headquartersRef = useRef<HTMLDivElement>(null);
    const { curSize } = props;

    useEffect(() => {
        if (!headquartersRef || !headquartersRef.current) {
            return;
        }

        const headquartersEl = headquartersRef.current;
        const headquartersWidth = headquartersEl.clientWidth;

        headquartersEl.style.height = `${Math.floor(headquartersWidth / 2)}px`;
    }, [curSize]);

    return (
        <SectionForm id={id}>
            <h3 className="sec__title font_lab">수사대 본부</h3>
            <p className="sec__txt">사건수사 중 궁금한 점이나 문제가 발생했다면 수사대 본부로 연락주세요!</p>
            <div className="headquarters__image" ref={headquartersRef} />
            <MciButton
                className="btn_headquarters"
                label="수사대 본부로 이동하기"
                color="transparent_orange"
                href="https://www.facebook.com/office.MCI"
            />
        </SectionForm>
    );
};

export default HeadquartersSection;
