import { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { RouterWithLocation } from '../App';
import AlertMessageForm from '../components/common/alert-message/AlertMessageForm';
import PassForm from '../components/pass/PassForm';
import { resetLocation } from '../utils/redirectUtils';

const Pass = (props: RouterWithLocation): ReactElement => {
    const { location } = props;
    const [locationMessage, setLocationMessage] = useState('');

    useEffect(() => {
        if (!location.state || !location.state.message) {
            setLocationMessage('');
            return;
        }

        setLocationMessage(location.state.message);
        resetLocation(props);
    }, []);

    const handleAlertClose = () => {
        resetLocation(props);
        setLocationMessage('');
    };

    return (
        <main className="mci_pass">
            <AlertMessageForm alertType={locationMessage} onClose={handleAlertClose} />
            <PassForm />
        </main>
    );
};

export default Pass;
