import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import PopUpForm, { ShowProps } from './PopUpForm';
import MciPass from '../../common/mci-pass/MciPass';
import { Badge } from '../badge/badge-list';

const slideBtnSize = 50;
const slideWidth = 670 + slideBtnSize * 2;
interface PassSlideProps extends ShowProps {
    data: Badge[];
}

const PassSlide = (props: PassSlideProps) => {
    const id = 'popup__badge__list';
    const { isView, setView, data } = props;

    const usersBadges = data.filter((badge) => {
        return badge.have;
    });

    SwiperCore.use([EffectFade, Navigation, Pagination]);

    return (
        <PopUpForm id={id} isView={isView} setView={setView}>
            <Swiper
                className={!usersBadges || usersBadges.length === 0 ? 'no_badges' : ''}
                style={{ width: `${slideWidth}px` }}
                effect={'fade'}
                fadeEffect={{ crossFade: true }}
                navigation
                pagination={{ type: 'fraction' }}
            >
                {usersBadges.length > 0 &&
                    usersBadges.map((badge) => {
                        return (
                            <SwiperSlide key={badge.id}>
                                <MciPass type="badge">
                                    <div className="badge__bg">
                                        <img className="badge__icon" src={badge.icon} alt={badge.name} />
                                    </div>
                                </MciPass>
                            </SwiperSlide>
                        );
                    })}

                {(!usersBadges || usersBadges.length <= 0) && (
                    <div className="no__badges__box">
                        <span className="no__badges__icon" />
                        <p className="no__badges__txt">뱃지 획득 전!</p>
                    </div>
                )}
            </Swiper>
        </PopUpForm>
    );
};

export default PassSlide;
