import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProvider } from './context/AppContext';
import firebase from 'firebase/app';
import environment from './environments/environment';
import { initFacebookSDK } from './utils/fbUtils';
import { BrowserRouter } from 'react-router-dom';

firebase.initializeApp(environment.firebaseConfig);

initFacebookSDK();

ReactDOM.render(
    <React.StrictMode>
        <AppProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AppProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
