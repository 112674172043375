import './alert-message.scss';

interface AlertProps {
    alertType: string | null;
    onClose: Function;
}

const AlertMessageForm = (props: AlertProps) => {
    const { alertType, onClose } = props;

    const getAlertMessageClassName = (): string => {
        const ALERT_MESSAGE_FORM = 'alert__message__form';

        return !alertType || alertType === '' ? ALERT_MESSAGE_FORM : `${ALERT_MESSAGE_FORM} show`;
    };

    return (
        <div className={getAlertMessageClassName()}>
            <p className="message">{setAlertMessage(alertType)}</p>
            <button className="alert__btn__close" onClick={() => onClose()} />
        </div>
    );
};

export default AlertMessageForm;

const setAlertMessage = (alertType: string | null): string => {
    switch (alertType) {
        case 'applied':
            return '이미 수사대 지원을 완료하였습니다. 사건을 해결하러 출동해주세요!';

        case 'didNotApply':
            return '아직 수사대 지원을 하지 않으셨군요. 수사대 지원부터 해주세요!';

        case 'didNotLogin':
            return '로그인을 해야 게임 플레이가 가능합니다. 로그인을 해주세요!';

        default:
            return '';
    }
};
