import GameSetPage from '../components/game-settings/GameSetPage';

const GameSettings = () => {
    return (
        <main className="mci_game_set bg_black_img">
            <GameSetPage />
        </main>
    );
};

export default GameSettings;
