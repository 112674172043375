import { useEffect, useState } from 'react';
import { useAppState } from '../../context/AppContext';
import { getGiveBadgesFn, isValidBadge } from '../../utils/badgeUtils';
import MciButton from '../common/button/MciButton';
import LoadingForm from '../common/loaindg/LoadingForm';
import './game-set.scss';

const CHECKED_KEY = 'mci-game-set-checked';
const BADGE_NAME = 'complete';

const GameSetPage = () => {
    const { user } = useAppState();
    const [checkedGameSet, setCheckedGameSet] = useState(false);
    const [mcDownloaded, setMcDownloaded] = useState(false);
    const [cobotDownloaded, setCobotDownloaded] = useState(false);

    useEffect(() => {
        if (mcDownloaded && cobotDownloaded) {
            window.localStorage.setItem(CHECKED_KEY, JSON.stringify(true));

            if (user && !isValidBadge(BADGE_NAME, user)) {
                getGiveBadgesFn(BADGE_NAME, user);
            }
        }
    }, [mcDownloaded, cobotDownloaded, user]);

    useEffect(() => {
        const isDownloaded = window.localStorage.getItem(CHECKED_KEY);

        if (isDownloaded) {
            setMcDownloaded(true);
            setCobotDownloaded(true);
        }
    });

    if (checkedGameSet) {
        return <LoadingForm type="investigation" />;
    }

    return (
        <div className="game__settings__wrap">
            <h2 className="game__title font_lab">수사 준비를 시작하세요!</h2>
            <ul className="settings__list">
                <li className="download__mci">
                    <h3 className="settings__title">마인크래프트 다운로드</h3>
                    <ol className="download__order">
                        <li>1.마인크래프트 앱을 다운로드 합니다.</li>
                        <li>
                            2.[내 PC] - [다운로드] 에<br />
                            Minecraft Education Edition을 두 번 클릭하여 설치합니다.
                        </li>
                        <li>
                            3.마인크래프트가 실행되었다면, <br />
                            다시 원래 페이지로 돌아와주세요.(아직 준비할 게 조금 남아있답니다!)
                        </li>
                        <li>
                            <MciButton
                                className="btn_mci"
                                color="black"
                                withIcon={true}
                                href="https://aka.ms/downloadmee-desktopApp"
                                onClick={() => setMcDownloaded(true)}
                            />
                        </li>
                    </ol>
                </li>
                <li className="download__cobot">
                    <h3 className="settings__title">수사 보고 앱</h3>
                    <ol className="download__order">
                        <li>1.수사 보고 앱을 다운로드 합니다.</li>
                        <li>2.[내 PC] - [다운로드] 에서 cobot-i를 두 번 클릭합니다.</li>
                        <li>
                            3. Window의 PC보호 창에서 ‘추가정보’를 클릭하고
                            <br />
                            아래에 생긴 [실행]을 누릅니다.
                        </li>
                        <li>4.위 단계를 모두 마치면 [수사 준비 완료] 버튼을 누릅니다.</li>
                        <li>
                            <MciButton
                                className="btn_cobot_i"
                                label="수사 보고 앱"
                                color="black"
                                withIcon={true}
                                href="https://update.cobot-i.com:5000/download/latest/"
                                onClick={() => setCobotDownloaded(true)}
                            />
                        </li>
                    </ol>
                </li>
            </ul>
            <MciButton
                className="btn_game_set"
                label="수사 준비 완료"
                color="transparent_orange"
                disabled={!mcDownloaded || !cobotDownloaded}
                onClick={() => setCheckedGameSet(true)}
            />
        </div>
    );
};

export default GameSetPage;
