import badge from './icon/badge.svg';
import complete from './icon/complete.svg';
import courage from './icon/courage.svg';
import paragliding from './icon/paragliding.svg';
import smart from './icon/smart.svg';
import brilliant from './icon/brilliant.svg';
import school from './icon/school.svg';
import celebration from './icon/celebration.svg';
import trophy from './icon/trophy.svg';
import flare from './icon/flare.svg';
import medal from './icon/medal.svg';
import diligent from './icon/diligent.svg';
import alert from './icon/alert.svg';
import stars from './icon/stars.svg';
import movie from './icon/movie.svg';

export interface Badge {
    id: string;
    icon: string;
    name: string;
    explanation: string;
    have: boolean;
}

export const badges: Badge[] = [
    {
        id: 'badge',
        icon: badge,
        name: `수사대\n 지원 완료`,
        explanation: `수사대 지원을 \n완료한 수사관`,
        have: false
    },
    {
        id: 'complete',
        icon: complete,
        name: `출동준비\n 완료`,
        explanation: `마인크래프트와\n 코봇아이를 \n모두 다운로드한 수사관`,
        have: false
    },
    {
        id: 'courage',
        icon: courage,
        name: `대단한 용기 `,
        explanation: `단서를\n 처음 공유한 수사관`,
        have: false
    },
    {
        id: 'paragliding',
        icon: paragliding,
        name: `MCI\n 에이스 수사관`,
        explanation: `단서를 \n5개 이상\n 획득한 수사관`,
        have: false
    },
    {
        id: 'smart',
        icon: smart,
        name: `단서 찾기\n 마스터`,
        explanation: `단서를\n 10개 이상\n 획득한 수사관`,
        have: false
    },
    {
        id: `brilliant`,
        icon: brilliant,
        name: `MCI\n 천재 수사관`,
        explanation: `단서를 \n10번 이상\n 공유한 수사관`,
        have: false
    },
    {
        id: `school`,
        icon: school,
        name: `첫 번째\n임무 완료`,
        explanation: `첫 번째 미션을 \n완수한 수사관`,
        have: false
    },
    {
        id: `celebration`,
        icon: celebration,
        name: `두 번째\n 임무 완료`,
        explanation: `두 번째 미션을 \n완수한 수사관`,
        have: false
    },
    {
        id: `trophy`,
        icon: trophy,
        name: `마지막\n 임무 완료`,
        explanation: `세 번째 미션을 \n완료한 수사관`,
        have: false
    },
    {
        id: `flare`,
        icon: flare,
        name: `정의의\n 이름으로`,
        explanation: `페이스북\n 수사대 본부에서\n 활동이 활발한 수사관`,
        have: false
    },
    {
        id: `medal`,
        icon: medal,
        name: `최강\n 수사관의\n 명예`,
        explanation: `수사대 본부에\n 답변을 가장 많이\n 남겨준 수사관`,
        have: false
    },
    {
        id: `diligent`,
        icon: diligent,
        name: `부지런한\n 수사관`,
        explanation: `매일 홈페이지에\n 접속한 \n열정적인 수사관`,
        have: false
    },
    {
        id: `alert`,
        icon: alert,
        name: `잊혀진\n 마크코딩\n수사대`,
        explanation: `5일 이상\n 접속하지 않은\n 게으른 수사관`,
        have: false
    },
    {
        id: `stars`,
        icon: stars,
        name: `수사관 20`,
        explanation: `스무 번째로\n 수사대에 지원한\n 수사관 20!`,
        have: false
    },
    {
        id: `movie`,
        icon: movie,
        name: `단서 공유\n 100회`,
        explanation: `수사대 본부에 \n단서를\n 100회 공유한 수사관`,
        have: false
    }
];
