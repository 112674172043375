import headerSuspectA from '../../../images/header_suspectA.png';
import headerSuspectB from '../../../images/header_suspectB.png';
import headerSuspectC from '../../../images/header_suspectC.png';
import headerSuspectUnknown from '../../../images/header_suspect_unknown.png';
import { useEffect, useState } from 'react';
import './loading.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface LoadingData {
    name: string;
    header: string;
    txt: string;
}

interface LoadingProps extends RouteComponentProps {
    type: 'apply' | 'investigation';
}

const LoadingForm = (props: LoadingProps) => {
    const { type, history } = props;
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const time = 2000;
        type === 'apply' ? setCurrentIndex(0) : setCurrentIndex(2);

        const currentName = setInterval(() => {
            setCurrentIndex(currentIndex + 1);
        }, time);

        if (type === 'investigation') {
            setTimeout(() => {
                history.push('/pass');
            }, time * 2);
        }

        return () => {
            clearInterval(currentName);
        };
    }, [type]);

    return (
        <div className="loading__page bg_black_img">
            <ul className={`mugshots ${type}_page`}>
                {loadingData.map((item: LoadingData, idx) => {
                    return (
                        <li key={item.name}>
                            <div className={`mugshot ${item.name}`} style={getBackgroundImageStyle(item.header)} />
                            {idx === currentIndex && <p className="loading__txt">{loadingData[currentIndex].txt}</p>}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default withRouter(LoadingForm);

const getBackgroundImageStyle = (img: string) => {
    return { backgroundImage: `url(${img})` };
};

const loadingData: LoadingData[] = [
    {
        name: 'suspectA',
        header: headerSuspectA,
        txt: '페이스북 그룹에 가입하면 추가 힌트를 얻을 수 있습니다!'
    },
    {
        name: 'suspectB',
        header: headerSuspectB,
        txt: '수사가 어려울 때는 코비에게 질문해 보세요!'
    },
    {
        name: 'suspectC',
        header: headerSuspectC,
        txt: '범인은 과연 누구일까요?'
    },
    {
        name: 'suspectUnknown',
        header: headerSuspectUnknown,
        txt: '수사관님의 성공적인 수사를 기대합니다!'
    }
];
