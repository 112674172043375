import moment from 'moment';
import { useEffect, useReducer, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { postVideoToGroup, share } from '../../../../utils/fbUtils';
import MciButton from '../../../common/button/MciButton';
import PopUpForm, { ShowProps } from '../PopUpForm';
import VideoControls from './VideoControls';

export interface VideoProperties {
    id: string;
    video_url: string;
    created: any;
    createdDate: string;
}
export interface VideoControlsState extends ProgressData {
    playing: boolean;
    muted: boolean;
    volume: number;
    fullscreen: boolean;
    seeking: boolean;
}
interface ProgressData {
    played: number;
    loaded: number;
    loadedSeconds: number;
    playedSeconds: number;
}

type VideoAction =
    | { type: 'PLAY_PAUSE'; playing: boolean }
    | { type: 'SOUND_ON_OFF' }
    | { type: 'VOLUME_CHANGE'; volume: number; muted: boolean }
    | { type: 'FULL_SCREEN' }
    | { type: 'SET_PLAYED'; played: number }
    | { type: 'PROGRESS_BAR' }
    | { type: 'SET_PROGRESS_DATA'; played: number; loaded: number; loadedSeconds: number; playedSeconds: number };

interface PopUpVideopRrops extends ShowProps {
    data: VideoProperties;
}

const reducer = (state: VideoControlsState, action: VideoAction): VideoControlsState => {
    switch (action.type) {
        case 'PLAY_PAUSE':
            return {
                ...state,
                playing: action.playing
            };
        case 'SOUND_ON_OFF':
            return {
                ...state,
                muted: !state.muted
            };
        case 'VOLUME_CHANGE':
            return {
                ...state,
                volume: action.volume,
                muted: action.muted
            };
        case 'FULL_SCREEN':
            return {
                ...state,
                fullscreen: !state.fullscreen
            };
        case 'SET_PLAYED':
            return {
                ...state,
                played: action.played
            };
        case 'PROGRESS_BAR':
            return {
                ...state,
                seeking: !state.seeking
            };
        case 'SET_PROGRESS_DATA':
            return {
                ...state,
                played: action.played,
                loaded: action.loaded,
                loadedSeconds: action.loadedSeconds,
                playedSeconds: action.playedSeconds
            };

        default:
            return state;
    }
};

const PopUpVideo = (props: PopUpVideopRrops) => {
    const id = 'popup__video';
    const { isView, setView, data } = props;
    const [videoState, videoDispatch] = useReducer(reducer, {
        playing: false,
        played: 0,
        muted: false,
        volume: 0.5,
        fullscreen: false,
        seeking: false,
        loaded: 0,
        loadedSeconds: 0,
        playedSeconds: 0
    });

    const { playing, played, muted, volume, fullscreen, seeking } = videoState;
    const videoContainerRef = useRef<any>();
    const playerRef = useRef(null);
    const [posting, setPosting] = useState<boolean>(false);
    const [posted, setPosted] = useState<boolean>(false);
    const [description, setDescription] = useState('');
    const [postBtnActive, setPostBtnActive] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setPosted(false);
    }, [data]);

    const handlePlayPause = () => {
        videoDispatch({ type: 'PLAY_PAUSE', playing: !playing });
    };
    const handlePlay = () => {
        videoDispatch({ type: 'PLAY_PAUSE', playing: true });
    };
    const handlePause = () => {
        videoDispatch({ type: 'PLAY_PAUSE', playing: false });
    };

    const handleProgress = (data: any) => {
        if (!seeking) {
            videoDispatch({ type: 'SET_PROGRESS_DATA', ...data });
        }
    };

    const handleToggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle(videoContainerRef.current);
        }
        videoDispatch({ type: 'FULL_SCREEN' });
    };

    const { elapasedTime, totalDuration } = DurationFunctional(playerRef);

    const { handleSoundSetting, handleVolumeChange } = SoundFunctional(videoDispatch);

    const { handleSeek, handleSeekMouseDown, handleSeekMouseUp } = ProgressBarFunctional(videoDispatch, playerRef);

    const postVideo = () => {
        // TODO: 게시기능 가능할 때 복원
        // const videoUrl = data.video_url;
        // const callback = () => {
        //     setPosting(false);
        //     setPosted(true);
        // };

        // setPosting(true);
        // postVideoToGroup(videoUrl, description, callback);

        if (!props.data.id) {
            return;
        }
        share(`https://cobot-i.com/messages/${props.data.id}`);
    };

    useEffect(() => {
        const today = moment().format('YYYY-MM-DD HH:MM');
        const releaseDate = '2021-09-11 11:00';
        const isValidDay = moment(today).isAfter(moment(releaseDate));

        isValidDay ? setPostBtnActive(true) : setPostBtnActive(false);
    }, []);

    useEffect(() => {
        if (posting || posted) {
            setReadOnly(true);
        }
    }, [posting, posted]);

    return (
        <PopUpForm id={id} isView={isView} setView={setView}>
            <div className={`video__box ${fullscreen ? 'fullscreen' : ''}`} ref={videoContainerRef}>
                <ReactPlayer
                    className="player__box"
                    ref={playerRef}
                    url={data?.video_url}
                    width={'100%'}
                    height={'100%'}
                    controls={false}
                    playing={playing}
                    muted={muted}
                    volume={volume}
                    played={played}
                    onProgress={handleProgress}
                    onPlay={handlePlay}
                    onPause={handlePause}
                />
                <VideoControls
                    videoControl={videoState}
                    onPlayPause={handlePlayPause}
                    elapsedTime={elapasedTime}
                    totalDuration={totalDuration}
                    onSoundSetting={handleSoundSetting}
                    onVolumeChange={handleVolumeChange}
                    onToggleFullScreen={handleToggleFullScreen}
                    onSeek={handleSeek}
                    onSeekMouseDown={handleSeekMouseDown}
                    onSeekMouseUp={handleSeekMouseUp}
                />
            </div>
            <>
                {/* TODO: 게시기능 가능할 때 복원 postBtnActive && (
                    <textarea
                        className="post__txt"
                        placeholder="수집한 증거와 함께 보고할 내용을 입력해보세요 : )"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                )*/}
                <div className="share__txt">
                    페이스북에 공유하기 창이 열리면
                    <br />
                    [그룹에 공유] 버튼으로 [MCI 마크코딩 신입수사관]을 선택해주세요!
                </div>
            </>
            {posting ? (
                <MciButton className="btn_reporting_evidence" label="본부에 보고중.." disabled color="orange" />
            ) : (
                <></>
            )}
            {posted ? (
                <MciButton className="btn_reporting_evidence" label="보고를 완료했습니다!" disabled color="orange" />
            ) : (
                <></>
            )}
            {!posting && !posted && postBtnActive ? (
                <MciButton
                    onClick={postVideo}
                    className="btn_reporting_evidence"
                    label="수집한 증거 본부에 보고하기"
                    color="orange"
                />
            ) : (
                <></>
            )}
        </PopUpForm>
    );
};

export default PopUpVideo;

const DurationFunctional = (playerRef: any) => {
    const calculateTime = (seconds: number) => {
        if (isNaN(seconds)) {
            return `00:00`;
        }
        const date = new Date(seconds * 1000);
        const hour = date.getUTCHours();
        const minute = date.getUTCMinutes();
        const second = date.getUTCSeconds().toString().padStart(2, '0');

        if (hour) {
            return `${hour}:${minute.toString().padStart(2, '0')}:${second}`;
        }
        return `${minute}:${second}`;
    };

    const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00';

    const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00';

    const totalDuration = calculateTime(duration);
    const elapasedTime = calculateTime(currentTime);

    return { totalDuration, elapasedTime };
};

const SoundFunctional = (videoDispatch: any) => {
    const handleSoundSetting = () => {
        videoDispatch({ type: 'SOUND_ON_OFF' });
    };

    const handleVolumeChange = (e: Event, newValue: number) => {
        videoDispatch({
            type: 'VOLUME_CHANGE',
            volume: newValue / 100,
            muted: newValue === 0 ? true : false
        });
    };

    return { handleSoundSetting, handleVolumeChange };
};

const ProgressBarFunctional = (videoDispatch: any, playerRef: any) => {
    const handleSeek = (e: Event, newValue: number) => {
        videoDispatch({
            type: 'SET_PLAYED',
            played: newValue / 100
        });
    };

    const handleSeekMouseDown = () => {
        videoDispatch({
            type: 'PROGRESS_BAR',
            seeking: true
        });
    };

    const handleSeekMouseUp = (e: Event, newValue: number) => {
        videoDispatch({
            type: 'PROGRESS_BAR',
            seeking: false
        });
        playerRef.current.seekTo(newValue / 100);
    };

    return { handleSeek, handleSeekMouseDown, handleSeekMouseUp };
};
