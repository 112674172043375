import './button.scss';
import { MouseEventHandler } from 'react';
interface ButtonProps {
    className?: string;
    label?: string;
    color: 'blue' | 'transparent_orange' | 'black' | 'orange' | 'black_dark';
    onClick?: Function;
    withIcon?: boolean;
    disabled?: boolean;
    href?: string;
    target?: string;
}

const MciButton = (props: ButtonProps) => {
    const { className, label, color, onClick, disabled, withIcon, href } = props;
    let { target } = props;
    const getButtonClassName = () => {
        return `${color} ${className ?? ''} ${withIcon ? 'with_icon' : ''}`;
    };

    if (!target) {
        target = '_blanck';
    }

    const handleClick = (event: any) => {
        const { nodeName } = event.target;

        if (nodeName !== 'A') {
            return;
        }

        if (onClick) {
            onClick();
        }
    };

    return (
        <button className={getButtonClassName()} onClick={(event) => handleClick(event)} disabled={disabled}>
            {!!href ? (
                <a className="btn_link" href={href} target={target}>
                    {withIcon && <span className="icon" />}
                    {label}
                </a>
            ) : (
                <>
                    {withIcon && <span className="icon" />}
                    {label}
                </>
            )}
        </button>
    );
};

export default MciButton;
