import './mci-pass.scss';
import { useAppState } from '../../../context/AppContext';
import { detectivePictures, Detective } from '../../application/ApplicationPage';

interface PassCssProperties {
    backgroundImage: string;
}

interface PassProps {
    type: 'pass' | 'badge';
    icon?: string;
    children?: JSX.Element[] | JSX.Element;
}

const MciPass = (props: PassProps) => {
    const { user } = useAppState();

    const getPhotoStyle = (): PassCssProperties | undefined => {
        if (!user || !user.photo) {
            return;
        }

        const getImgUrl = detectivePictures.find((data: Detective) => {
            return data.id === user.photo;
        });

        if (getImgUrl && getImgUrl.img) {
            return getBackgroundImgStyle(getImgUrl.img);
        }
    };

    return (
        <div className={`mci__pass ${props.type}`}>
            <div className="pass__inr">
                <div className="detective__face">
                    <div style={getPhotoStyle()} />
                </div>
                <p className="detective__name">{user?.displayName} 수사관</p>
                <div className="pass__txt e_mail">
                    <b className="txt__title"> 이메일</b>
                    <p className="txt__content">{user?.mcEmail}</p>
                </div>
                <div className="pass__txt password">
                    <b className="txt__title">비밀번호</b>
                    <p className="txt__content">{user?.mcPassword}</p>
                </div>
                {props.type === 'pass' && <div className="pass__logo" />}
                {props.children}
            </div>
        </div>
    );
};

const getBackgroundImgStyle = (img: string): PassCssProperties => {
    return { backgroundImage: `url(${img})` };
};

export default MciPass;
