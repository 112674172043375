import SectionForm from './SectionForm';
import MciButton from '../common/button/MciButton';
import imgCase1 from '../../images/case1.png';
import imgCase2 from '../../images/case2.jpg';
import imgCase3 from '../../images/case3.jpg'; // TODO: 이미지 변경해야함.
import icoLock from '../../images/ico_lock.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useAppDispatch, useAppState } from '../../context/AppContext';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import authUtils from '../../utils/auth.utils';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import { CurSizeWithRouter } from '../../App';

interface CriminalCaseProperties {
    id: string;
    openDate: string;
    isOpened: boolean;
    scene: string;
    caseTitle: string;
    case: string;
    case2: string;
    downloadLink: string;
}

export interface CurrentCase {
    caseTitle: string;
    downloadLink: string;
}

const slideBtnSize = 50;
const slideWidth = `calc(100% + ${slideBtnSize * 2})`;

const RELEASE_DATE = '20210910';
const COMMINGSOON = '';

const CriminalCaseSection = (props: CurSizeWithRouter) => {
    const id = 'home__criminal__case';
    const { history, curSize } = props;
    const caseRefs = criminalCases.map(() => useRef<HTMLDivElement>(null));
    const [today, setToday] = useState(RELEASE_DATE);
    const [currentIsOpen, setCurrentIsOpen] = useState(true);

    const appState = useAppState();
    const appDispatch = useAppDispatch();

    const { isLogin, didApply } = appState;

    let criminalCaseBtn;

    if (!isLogin) {
        criminalCaseBtn = (
            <MciButton
                className="btn_criminal_case"
                label="로그인 하고 사건 해결하기"
                color="transparent_orange"
                onClick={() => authUtils.login(appDispatch)}
            />
        );
    } else if (!didApply) {
        criminalCaseBtn = (
            <MciButton
                className="btn_criminal_case"
                label="수사대 지원하고 사건 해결하기"
                color="transparent_orange"
                onClick={() => history.push('/application')}
            />
        );
    } else {
        criminalCaseBtn = (
            <MciButton
                className="btn_criminal_case"
                label="사건 해결하기"
                color="transparent_orange"
                onClick={() => history.push('/pass')}
            />
        );
    }

    useEffect(() => {
        caseRefs.map((caseEl: React.RefObject<HTMLDivElement>) => {
            if (!caseEl || !caseEl.current) {
                return;
            }
            const caseElWidth = caseEl.current.clientWidth;
            caseEl.current.style.height = `${Math.floor(caseElWidth / 16) * 9}px`;
        });
    }, [curSize]);

    SwiperCore.use([Navigation]);

    useEffect(() => {
        setToday(moment().format(`YYYYMMDD`));

        const currentDay = (date: string) => {
            if (date === COMMINGSOON) {
                return;
            }
            return moment(date).isSameOrBefore(today);
        };

        criminalCases.map((item: CriminalCaseProperties) => {
            if (currentDay(item.openDate)) {
                item.isOpened = true;
                setCurrentIsOpen(true);
            }
            return item;
        });
    }, [today]);

    const setOpenDate = (date: string) => {
        return date === COMMINGSOON ? date : moment(date).format('YYYY. MM. DD dddd');
    };

    const handleSlideChange = (swiper: SwiperCore) => {
        const { realIndex } = swiper;

        if (criminalCases[realIndex].isOpened) {
            setInitialCurrentCaseInfo(criminalCases[realIndex]);
            setCurrentIsOpen(true);
        } else {
            setCurrentIsOpen(false);
        }
    };

    const setInitialCurrentCaseInfo = (data: CriminalCaseProperties) => {
        appDispatch({
            type: 'SET_CURRENT_CRIMINAL_CASE',
            caseTitle: data.caseTitle,
            downloadLink: data.downloadLink
        });
    };

    return (
        <SectionForm id={id}>
            <Swiper
                style={{ width: `${slideWidth}px` }}
                loop={true}
                speed={1000}
                navigation
                onSlideChange={handleSlideChange}
            >
                {criminalCases.map((criminalCase: CriminalCaseProperties, idx) => {
                    return (
                        <SwiperSlide className={`slide ${criminalCase.isOpened ? 'open' : ''}`} key={criminalCase.id}>
                            <div
                                className="crime__scene"
                                style={{
                                    backgroundImage: `url(${criminalCase.isOpened && criminalCase.scene})`
                                }}
                                ref={caseRefs[idx]}
                            >
                                {!criminalCase.isOpened && (
                                    <span className="icon__lock" style={{ backgroundImage: `url(${icoLock})` }} />
                                )}
                            </div>
                            <strong className="font_lab">{criminalCase.caseTitle}</strong>
                            {!criminalCase.isOpened ? (
                                <span className={`case ${criminalCase.id} font_lab`}>
                                    {setOpenDate(criminalCase.openDate)}
                                </span>
                            ) : (
                                <p className="case">
                                    {criminalCase.case}
                                    <span>{criminalCase.case2}</span>
                                </p>
                            )}

                            <div className="slide__pagination">
                                <div className="pagination__stic">
                                    <div className="pagination__bar" style={positionBar(criminalCase.id)} />
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="btn__box">
                {currentIsOpen && criminalCaseBtn}
                {isLogin && didApply && (
                    <MciButton
                        className="btn_criminal_view_note"
                        label="수사수첩 펼치기"
                        color="black"
                        onClick={() => history.push('/notes')}
                    />
                )}
            </div>
        </SectionForm>
    );
};

export const criminalCases: CriminalCaseProperties[] = [
    {
        id: 'case_01',
        openDate: '20210910',
        isOpened: false,
        scene: imgCase1,
        caseTitle: '첫 번째 수사 : 가랏 신입수사관, 너로 정했다!',
        case: '2021년 8월 20일, 포클교수가 실종되었다.',
        case2: '어딘가 의미심장한 사람들과 이리저리 얽힌 단서들을 파헤쳐라!',
        downloadLink:
            'https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/mci_assets%2F%EC%B2%AB%EB%B2%88%EC%A7%B8%EC%88%98%EC%82%AC_%EA%B0%80%EB%9E%8F%20%EC%8B%A0%EC%9E%85%EC%88%98%EC%82%AC%EA%B4%80%2C%20%EB%84%88%EB%A1%9C%20%EC%A0%95%ED%96%88%EB%8B%A4!.mcworld?alt=media&token=1c3f0fa0-58d3-4360-a655-91c76fa52f17'
    },
    {
        id: 'case_02',
        openDate: '20210921',
        isOpened: false,
        scene: imgCase2,
        caseTitle: '두 번째 수사 : 미궁 속으로',
        case: '더 이상 아군조차 믿을 수 없다. 하지만 신입 수사관은 중심을 잃지 않아야 해.',
        case2: ' 코딩으로 정밀 수사를 강행한다.',
        downloadLink:
            'https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/mci_assets%2F%EB%91%90%20%EB%B2%88%EC%A7%B8%20%EC%88%98%EC%82%AC_%EB%AF%B8%EA%B6%81%20%EC%86%8D%EC%9C%BC%EB%A1%9C.mcworld?alt=media&token=2787b0f2-53ee-4a10-b409-759fa88d059e'
    },
    {
        id: 'case_03',
        openDate: COMMINGSOON,
        isOpened: false,
        scene: imgCase3,
        caseTitle: '세 번째 수사 : 결정의 시간이 다가왔다.',
        case: '정신없이 쏟아지는 수사관들의 수많은 추리. 그 안에서 나는 답을 찾을 수 있을까?',
        case2: '범인을 추리하고 포클 교수를 찾아내어 구출하라!',
        downloadLink: ''
    }
];

const positionBar = (page: string) => {
    switch (page) {
        case 'case_01':
            return { left: 0, transform: 'translate(0, -50%)' };

        case 'case_02':
            return { left: '50%', transform: `translate(-50%, -50%)` };

        case 'case_03':
            return { left: 'auto', right: 0, transform: `translate(0, -50%)` };

        default:
            break;
    }
};

export default CriminalCaseSection;
