import { CurSizeWithRouter, RouterWithLocation } from '../App';

type RedirectProps = CurSizeWithRouter | RouterWithLocation;

const resetLocation = (props: RedirectProps) => {
    const { history, location } = props;
    const pathName = location.pathname;

    history.replace(pathName, undefined);
};

export { resetLocation };
