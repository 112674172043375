import SectionForm from './SectionForm';
import headerSuspectA from '../../images/header_suspectA.png';
import headerSuspectB from '../../images/header_suspectB.png';
import headerSuspectC from '../../images/header_suspectC.png';
import headerSuspectUnknown from '../../images/header_suspect_unknown.png';
import clickedHeaderSuspectA from '../../images/header_suspectA_clicked.png';
import clickedHeaderSuspectB from '../../images/header_suspectB_clicked.png';
import clickedHeaderSuspectC from '../../images/header_suspectC_clicked.png';
import clickedHeaderSuspectUnknown from '../../images/header_suspect_unknown_clicked.png';
import bodySuspectA from '../../images/body_suspectA.png';
import bodySuspectB from '../../images/body_suspectB.png';
import bodySuspectC from '../../images/body_suspectC.png';
import bodySuspectUnknown from '../../images/body_suspect_unknown.png';
import { useEffect, useRef, useState } from 'react';
import { CurSizeWithRouter } from '../../App';

interface CriminalsInfo {
    id: string;
    name: string;
    txt: string;
    header: string;
    clickHeader: string;
    body: string;
}

const CriminalsSection = (props: CurSizeWithRouter) => {
    const id = 'home__criminals';
    const criminalsRef = criminals.map(() => useRef<HTMLLIElement>(null));
    const { curSize } = props;
    const [chooseCriminal, setChooseCriminal] = useState('suspect_a');

    useEffect(() => {
        criminalsRef.map((liEl: React.RefObject<HTMLLIElement>) => {
            if (!liEl || !liEl.current) {
                return;
            }

            const liElWidth = liEl.current.clientWidth;
            liEl.current.style.height = `${Math.floor(liElWidth / 3) * 4}px`;
        });
    }, [curSize]);

    const checkingName = (name: string) => {
        return name === chooseCriminal ? 'on' : '';
    };

    const clickedHeaderIcon = (criminal: CriminalsInfo) => {
        const { id, clickHeader, header } = criminal;

        return id === chooseCriminal ? setBackgroundImgStyle(clickHeader) : setBackgroundImgStyle(header);
    };

    return (
        <SectionForm id={id}>
            <h3 className="sec__title font_lab">범인은 과연!</h3>
            <div className="list__of__criminals">
                <div>
                    <ul className="criminals__profile">
                        {criminals.map((info: CriminalsInfo, idx) => {
                            return (
                                <li key={info.id} className={checkingName(info.id)} ref={criminalsRef[idx]}>
                                    <div className="criminal__body" style={setBackgroundImgStyle(info.body)} />
                                    <b className="criminal__name font_lab">{info.name}</b>
                                    <p className="criminal__txt">{info.txt}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <ul className="mugshots">
                    {criminals.map((criminal: CriminalsInfo) => {
                        return (
                            <li key={criminal.id} onClick={() => setChooseCriminal(criminal.id)}>
                                <div className="mugshot" style={clickedHeaderIcon(criminal)} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </SectionForm>
    );
};

const criminals: CriminalsInfo[] = [
    {
        id: 'suspect_a',
        name: '용의자 A',
        header: headerSuspectA,
        clickHeader: clickedHeaderSuspectA,
        body: bodySuspectA,
        txt: '포클 교수와 함께 일하던 연구원 A는 평소 포클 교수가 자신의 쿠키를 말도 없이 먹어버리는 것에 대해 오랫동안 불만을 품고 있었던 것으로 밝혀졌다. 그의 일기장에서 분노를 담은 페이지가 발견되었다.'
    },
    {
        id: 'suspect_b',
        name: '용의자 B',
        header: headerSuspectB,
        clickHeader: clickedHeaderSuspectB,
        body: bodySuspectB,
        txt: '포클 교수의 이웃집 사람인 B는 경찰의 탐문수사에서 포클 교수가 실종되던 날 그를 보지 못하였다고 진술했으나, CCTV 탐색 결과 사건 당일 B와 포클 교수가 대화를 나누는 장면이 발견되었다.'
    },
    {
        id: 'suspect_c',
        name: '용의자 C',
        header: headerSuspectC,
        clickHeader: clickedHeaderSuspectC,
        body: bodySuspectC,
        txt: '경찰이 포클 교수의 동선을 집중적으로 탐문수사 하던 중 마주친 D에게 신분증을 요구하자 C는 지갑을 열었고, 지갑을 여는 순간 카드 하나를 바닥에 떨어트렸다. 그 카드는 포클 교수의 집 현관을 여는 카드키였고, C는 길을 가다가 주웠다고 해명했지만 경찰은 용의선상에 그를 포함했다.'
    },
    {
        id: 'unknown',
        name: '숨겨진 용의자',
        header: headerSuspectUnknown,
        clickHeader: clickedHeaderSuspectUnknown,
        body: bodySuspectUnknown,
        txt: '3명의 용의자와 관련된 단서들 외에, 행위자를 특정할 수 없는 단서들이 계속 발견된다. 수사 진행 중에 주변 npc들로부터 의문스러운 대사들을 듣는다.'
    }
];

const setBackgroundImgStyle = (img: string) => {
    return { backgroundImage: `url(${img})` };
};

export default CriminalsSection;
