import { useEffect } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { useAppState } from '../context/AppContext';

const GuardRoute = (props: any) => {
    const { isLogin, didApply, routeRedirected, endedEvent } = useAppState();
    const { component: Component, ...rest } = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    const {
        location: { pathname }
    } = rest;

    const setRouteComponent = (routeProps: RouteComponentProps) => {
        if (endedEvent) {
            if (pathname !== '/') {
                return <Redirect to={{ pathname: '/' }} />;
            }
        }

        if (!isLogin) {
            if (pathname !== '/game-set' && pathname !== '/') {
                return <Redirect to={{ pathname: '/', state: { message: 'didNotLogin' } }} />;
            }
        }

        if (!didApply) {
            if (pathname === '/notes' || pathname === '/pass') {
                return (
                    <Redirect
                        to={{
                            pathname: '/application',
                            state: { message: 'didNotApply' }
                        }}
                    />
                );
            }
        }

        if (isLogin && didApply && routeRedirected) {
            if (pathname === '/application') {
                return (
                    <Redirect
                        to={{
                            pathname: '/pass',
                            state: { message: 'applied' }
                        }}
                    />
                );
            }
        }

        return <Component {...routeProps} />;
    };

    return <Route {...rest} render={(routeProps) => setRouteComponent(routeProps)} />;
};

export default GuardRoute;
