import { useEffect, useState } from 'react';
import { Route, Switch, withRouter, BrowserRouter, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import Header from './components/header/Header';
import Home, { wheelEventForPreventDefault } from './router/Home';
import Note from './router/Note';
import Footer from './components/footer/Footer';
import Application from './router/Application';
import GameSettings from './router/GameSettings';
import './styles/reset.scss';
import Pass from './router/Pass';
import { useAppDispatch, useAppState } from './context/AppContext';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { finalize } from 'rxjs';
import authUtils from './utils/auth.utils';
import { criminalCases } from './components/home/CriminalCaseSection';
import GuardRoute from './router/GuardRoute';

type LocationState = {
    message: string;
};

type CurSize = {
    curSize: number;
};

export type RouterWithLocation = RouteComponentProps<{}, StaticContext, LocationState>;
export type CurSizeWithRouter = RouterWithLocation & CurSize;

const App = (props: RouteComponentProps) => {
    const { user, caseTitle, downloadLink, savedUserLoaded, endedEvent } = useAppState();
    const appStateDispatch = useAppDispatch();
    const [curSize, setCurSize] = useState(window.innerWidth);
    const [content, setContent] = useState(<div>defualt</div>);
    const { location } = props;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (location.pathname !== '/') {
            window.removeEventListener('wheel', wheelEventForPreventDefault);
        }
    }, [location]);

    useEffect(() => {
        if (!user) {
            const afAuth = firebase.auth();

            const afAuthStateUnsubscribe: firebase.Unsubscribe = afAuth.onAuthStateChanged(
                (savedUser: firebase.User | null) => {
                    if (!savedUser) {
                        afAuthStateUnsubscribe();
                        appStateDispatch({ type: 'SET_SAVED_USER_LOADED', loaded: true });
                        return;
                    }
                    authUtils
                        .setUserInfo(savedUser.uid, appStateDispatch)
                        .pipe(
                            finalize(() => {
                                afAuthStateUnsubscribe();
                                appStateDispatch({ type: 'SET_SAVED_USER_LOADED', loaded: true });
                            })
                        )
                        .subscribe({
                            error: (err) => {
                                console.error(err);
                            }
                        });
                }
            );
        }
    }, [user]);

    const handleResize = () => {
        setCurSize(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, true);

        return () => {
            window.removeEventListener('resize', handleResize, true);
        };
    }, []);

    useEffect(() => {
        if (!caseTitle || !downloadLink) {
            appStateDispatch({
                type: 'SET_CURRENT_CRIMINAL_CASE',
                caseTitle: criminalCases[0].caseTitle,
                downloadLink: criminalCases[0].downloadLink
            });
        }
    }, []);

    useEffect(() => {
        if (!savedUserLoaded) {
            setContent(<></>);
        } else {
            setContent(
                <BrowserRouter>
                    <Header />
                    <Switch>
                        <Route
                            path="/"
                            exact={true}
                            render={(routeProps: any) => {
                                return <Home curSize={curSize} {...routeProps} />;
                            }}
                        />
                        <GuardRoute path="/notes" component={Note} />
                        <GuardRoute path="/application" component={Application} />
                        <GuardRoute path="/game-set" component={GameSettings} />
                        <GuardRoute path="/pass" component={Pass} />
                    </Switch>
                    <Footer />
                </BrowserRouter>
            );
        }
    }, [savedUserLoaded]);

    const getMciPageClassName = (): string => {
        const MCI_PAGE = 'mci__page__wrap';
        return endedEvent ? `${MCI_PAGE} end_event` : MCI_PAGE;
    };

    return <div className={getMciPageClassName()}>{content}</div>;
};

export default withRouter(App);
