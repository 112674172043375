import { environment as prodEnvironment } from './environment.prod';

const devEnvironment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyDHSENQzKpZQjm74Y1GWD1TXd0jl4tY9ps',
        authDomain: 'chocobot-test.firebaseapp.com',
        projectId: 'chocobot-test',
        storageBucket: 'chocobot-test.appspot.com',
        messagingSenderId: '903884407257',
        appId: '1:903884407257:web:4c48733bd5c6d583f899c8'
    },
    protocol: 'pocl.cobot-i.com',
    fbAppId: '361388079024334',
};

let environment: typeof devEnvironment;

if (process.env.NODE_ENV === 'production') {
    environment = prodEnvironment;
} else {
    environment = devEnvironment;
}

export default environment;