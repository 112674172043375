import './end-event-popup.scss';
import MciButton from '../button/MciButton';
import { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';

const EndEventPopUp = () => {
    const typedRef = useRef<any>(null);
    const [typedComplete, setTypedComplete] = useState(false);
    const [showCursor, setShowCursor] = useState(true);

    useEffect(() => {
        if (!typedRef || !typedRef.current) {
            return;
        }

        const typeEl = typedRef.current;

        const options = {
            strings: [
                `Mci 마크코딩 수사본부에서 알립니다.\n신입 수사관의 적극적인 수사로 범인의 정체가 점점 드러나고 있습니다.\n 본부는 지금까지 보고받은 내용을 모두 경찰에 전달했습니다.\n수사관들은 다음 지시가 있을 때까지 재정비의 시간을 가지기 바랍니다.\n다음 수사 때 뵙도록 하겠습니다.`
            ],
            typeSpeed: 70,
            showCursor: showCursor,
            onComplete: () => {
                setTypedComplete(true);
            }
        };

        const typed = new Typed(typeEl, options);

        return () => {
            typed.destroy();
        };
    }, []);

    const setNotificationMsgClassName = (): string => {
        const NOTICE_MST_BOX = 'notification__message__box';
        return typedComplete ? `${NOTICE_MST_BOX} complete` : NOTICE_MST_BOX;
    };

    return (
        <div className="end__event__popup__form">
            <div className={setNotificationMsgClassName()}>
                <div className="messages font_lab">
                    <span ref={typedRef} />
                </div>
                <MciButton
                    className="btn_popup_header"
                    color="black_dark"
                    label="수사본부 방문하기"
                    href="https://www.facebook.com/office.MCI"
                />
            </div>
        </div>
    );
};

export default EndEventPopUp;
