import { useAppDispatch, useAppState } from '../../context/AppContext';
import MciButton from '../common/button/MciButton';
import SectionForm from './SectionForm';
import authUtils from '../../utils/auth.utils';
import { RouterProps, withRouter } from 'react-router-dom';

const MainSection = (props: RouterProps) => {
    const id = 'home__main';
    const { history } = props;
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const { isLogin, didApply } = appState;

    let mainBtn;

    if (!isLogin) {
        mainBtn = (
            <MciButton
                className="btn_main"
                label="로그인 하기"
                color="transparent_orange"
                onClick={() => authUtils.login(appDispatch)}
            />
        );
    } else if (!didApply) {
        mainBtn = (
            <MciButton
                className="btn_main"
                label="수사대 지원하기"
                color="transparent_orange"
                onClick={() => history.push('/application')}
            />
        );
    } else {
        mainBtn = (
            <MciButton
                className="btn_main"
                label="사건 해결하기"
                color="transparent_orange"
                onClick={() => history.push('/pass')}
            />
        );
    }

    return (
        <SectionForm id={id}>
            <h1 className="mci__logo">MCI</h1>
            {mainBtn}
            <>
                {(!isLogin || !didApply) && (
                    <p className="sec__txt">수사대에 지원하기 위해서 페이스북 로그인을 진행합니다.</p>
                )}
                {isLogin && didApply && (
                    <MciButton
                        className="btn_view_note"
                        label="수사수첩 펼치기"
                        color="black"
                        onClick={() => history.push('/notes')}
                    />
                )}
            </>
        </SectionForm>
    );
};

export default withRouter(MainSection);
