import men from './icon/detective_men.png';
import cap from './icon/detective_cap.png';
import alien from './icon/detective_alien.png';
import bear from './icon/detective_bear.png';
import smile from './icon/detective_smile_face.png';

import './application.scss';
import { useAppDispatch, useAppState } from '../../context/AppContext';
import firebase from 'firebase/app';
import 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { UserMCIProperties } from '../../interfaces/MCIUserProperties';
import { from, mergeMap } from 'rxjs';
import LoadingForm from '../common/loaindg/LoadingForm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import authUtils from '../../utils/auth.utils';

export interface Detective {
    id: string;
    img: string;
}

export const detectivePictures: Detective[] = [
    { id: 'pic_men', img: men },
    { id: 'pic_cap', img: cap },
    { id: 'pic_alien', img: alien },
    { id: 'pic_bear', img: bear },
    { id: 'pic_smile', img: smile }
];

const KEY_DISPLAYNAME = 'displayName';
const KEY_BIRTH = 'birth';
const KEY_QNA_1 = 'qna1';
const KEY_PHOTO = 'photo';
const requiredKeys = [KEY_DISPLAYNAME, KEY_BIRTH, KEY_QNA_1, KEY_PHOTO];

const isValidFormData = (formData: FormData): boolean => {
    requiredKeys.forEach((key: string) => {
        if (!formData.has(key)) {
            return false;
        }
    });

    const nameData = String(formData.get(KEY_DISPLAYNAME));
    const birthData = String(formData.get(KEY_BIRTH));
    if (!nameData || !isValidBirth(birthData)) {
        return false;
    }

    return true;
};

const isValidBirth = (birth: string): boolean => {
    if (!birth) {
        return false;
    }
    const checkNum = /^[0-9]{8}$/g;
    return checkNum.test(birth);
};

const ApplicationPage = (props: RouteComponentProps): JSX.Element => {
    const { user } = useAppState();
    const appStateDispatch = useAppDispatch();
    const { history } = props;
    const [loading, setLoading] = useState(false);
    const [inputBirth, setInputBirth] = useState('');
    const [inputName, setInputName] = useState('');

    if (loading) {
        return <LoadingForm type="apply" />;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
        const value = e.target.value;
        const removeEmptyStr = value.slice(0, 8).replace(/(\s*)/g, '');

        return inputType === 'birth' ? setInputBirth(removeEmptyStr) : setInputName(removeEmptyStr);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!user) {
            return;
        }

        const formData = new FormData(e.target as HTMLFormElement);

        if (!isValidFormData(formData)) {
            return;
        }

        const afFunctions = firebase.functions();
        const updateUserInfoFn = afFunctions.httpsCallable('updateMCIUser');

        setLoading(true);
        appStateDispatch({ type: 'SET_ROUTE_REDIRECTED', routeRedirected: false });

        const mciProperties: UserMCIProperties = {
            uid: user.uid,
            displayName: String(formData.get(KEY_DISPLAYNAME)),
            birth: String(formData.get(KEY_BIRTH)),
            qna1: Boolean(formData.get(KEY_QNA_1)),
            photo: String(formData.get(KEY_PHOTO))
        };

        from(updateUserInfoFn({ uid: user.uid, mciProperties }))
            .pipe(
                mergeMap(() => {
                    return authUtils.setUserInfo(user.uid, appStateDispatch);
                })
            )
            .subscribe({
                next: () => {
                    setLoading(false);
                    history.push('/game-set');
                },
                error: (err) => console.error(err)
            });
    };

    return (
        <div className="application__wrap">
            <div className="application__board__base">
                <div className="application__info">
                    <div className="application__header">
                        <h2 className="app__title">수사대 지원서</h2>
                        <p className="app__txt">
                            사건을 해결하려면 수사대 지원서를 제출해야 합니다.
                            <br /> 훌륭한 수사관의 선별을 위해 성실하고 솔직하게 답변해 주세요.
                        </p>
                    </div>
                    <form onSubmit={onSubmit} id="form" className="application__main">
                        <div className="new__detective">
                            <strong className="app__sub__title">신입 수사관님, 아래의 빈 칸을 먼저 채워주세요!</strong>
                            <div className="input__box">
                                <input
                                    placeholder="당신의 이름은 무엇인가요?"
                                    id="detective__name"
                                    name={KEY_DISPLAYNAME}
                                    onChange={(e) => handleChange(e, 'name')}
                                    value={inputName}
                                />
                                <label htmlFor="detective__name" />
                            </div>
                            <div className="input__box birth">
                                <input
                                    placeholder="생년월일을 입력해 주세요. 예)20081101"
                                    id="detective__birth"
                                    name={KEY_BIRTH}
                                    type={'number'}
                                    onChange={(e) => handleChange(e, 'birth')}
                                    value={inputBirth}
                                />
                                <label htmlFor="detective__birth" />
                                {inputBirth.length < 8 && inputBirth.length !== 0 && (
                                    <span className="alert__txt">생년월일 8글자를 입력해주세요. 예)20081101</span>
                                )}
                            </div>
                        </div>
                        <div className="codding__exprience">
                            <strong className="app__sub__title">수사관님은 코딩을 해본적이 있나요?</strong>
                            <div className="radio__box">
                                <input id="yes_codding" name={KEY_QNA_1} value="true" type="radio" defaultChecked />
                                <label htmlFor="yes_codding">예. 코딩해본적 있어요.</label>
                            </div>
                            <div className="radio__box">
                                <input id="no_codding" name={KEY_QNA_1} value="false" type="radio" />
                                <label htmlFor="no_codding">아니요. 코딩은 처음이에요.</label>
                            </div>
                        </div>
                        <div className="detective__face">
                            <strong className="app__sub__title">출입증에 들어갈 사진을 골라주세요.</strong>
                            <div className="faces">
                                {detectivePictures.map((picture: Detective, idx) => {
                                    return (
                                        <div className="icon__box" key={picture.id}>
                                            <input
                                                type="radio"
                                                id={picture.id}
                                                name={KEY_PHOTO}
                                                value={picture.id}
                                                defaultChecked={idx === 0}
                                            />
                                            <label htmlFor={picture.id} style={setBackgroundImg(picture.img)} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="application__footer">
                            <p className="app__txt">
                                지원서를 제출하려면 지문을 꾹 눌러주세요!
                                <span>(서명)</span>
                            </p>
                            <button type="submit" className="ico_fingerprint" />
                        </div>
                    </form>
                </div>
                <div className="clip_body" />
                <div className="clip" />
            </div>
        </div>
    );
};

const setBackgroundImg = (img: string) => {
    return { backgroundImage: `url(${img})` };
};

export default withRouter(ApplicationPage);
