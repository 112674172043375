import { CurSizeWithRouter } from '../App';
import InvestigationNotes from '../components/notes/InvestigationNotes';

const Note = (props: CurSizeWithRouter) => {
    return (
        <main className="mci_note bg_black_img">
            <InvestigationNotes {...props} />
        </main>
    );
};

export default Note;
