import { useAppState } from '../../context/AppContext';
import MciButton from '../common/button/MciButton';
import MciPass from '../common/mci-pass/MciPass';
import './pass.scss';
import firebase from 'firebase/app';
import 'firebase/functions';
import { withRouter, RouterProps } from 'react-router-dom';

const PassForm = (props: RouterProps) => {
    const { history } = props;
    const { user, caseTitle, downloadLink } = useAppState();

    const openCoboti = () => {
        if (!user) {
            return;
        }
        const afFunctions = firebase.functions();
        const getAuthToken = afFunctions.httpsCallable('getAuthToken');
        getAuthToken({ id: user.uid }).then((result) => {
            const token = result.data;
            const appUrl = `pocl.cobot-i.com://caller=mci&token=${token}`;
            window.location.assign(appUrl);
        });
    };

    return (
        <div className="mci__pass__wrap">
            <div className="pass__box">
                <h2 className="pass__title font_lab">출입증이 발급되었습니다!</h2>
                <MciPass type="pass" />
            </div>
            <div className="pass__contents">
                <ol className="pass__order">
                    <li>
                        <span>1</span>[첫 번째 수사 : 사라진 포클교수] 버튼을 눌러
                        <br /> 마인크래프트 맵을 다운로드 합니다.
                    </li>
                    <li>
                        <span>2</span>수사 보고 앱 열기를 누릅니다.
                    </li>
                    <li>
                        <span>3</span>[내 PC] - [다운로드] 에서 맵을 두 번 클릭하여 실행합니다.
                    </li>
                    <li>
                        <span>4</span>수사관증에 표시된 이메일과 비밀번호를 이용하여 로그인합니다.
                    </li>
                </ol>
                <MciButton
                    className="btn_pass_report"
                    label="수사 보고 앱 열기"
                    onClick={openCoboti}
                    color="black"
                    withIcon={true}
                />
                <MciButton className="btn_pass" label={caseTitle} href={downloadLink} color="orange" />
                <p className="facebook__gruop">
                    <a href="https://www.facebook.com/groups/mci.markcoding" target="_blank" className="facebook_link">
                        페이스북 그룹
                    </a>
                    에 가입하면 다른 탐정들에게 질문을 할 수 있습니다.
                </p>
            </div>
            <div className="mci__ready__box">
                <span className="ready__txt">출동준비는 모두 마치셨나요?</span>
                <button className="btn__ready" onClick={() => history.push('/game-set')}>
                    <span className="icon__m" />
                </button>
            </div>
        </div>
    );
};

export default withRouter(PassForm);
