import React from 'react';
import './pop-up.scss';

export interface ShowProps {
    isView: boolean;
    setView: React.Dispatch<React.SetStateAction<boolean>>;
}
interface PopupProps extends ShowProps {
    id: string;
    children: JSX.Element[] | JSX.Element;
}

const PopUpForm = (props: PopupProps) => {
    const { id, children, isView, setView } = props;

    return (
        <>
            {isView && (
                <div className="pop__up__wrap">
                    <div id={id}>
                        {children}
                        <button className="btn_close" onClick={() => setView(false)} />
                    </div>
                </div>
            )}
        </>
    );
};

export default PopUpForm;
