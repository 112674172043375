import MciButton from '../common/button/MciButton';
import { badges, Badge } from './badge/badge-list';
import './note.scss';
import PopUpVideo, { VideoProperties } from './pop-up-form/pop-up-video/PopUpVideo';
import { useEffect, useRef, useState } from 'react';
import PassSlide from './pop-up-form/PassSlide';
import { useAppState } from '../../context/AppContext';
import firebase from 'firebase/app';
import moment from 'moment';
import { RouterProps } from 'react-router';

const videos: VideoProperties[] = [];

const InvestigationNotes = (props: RouterProps) => {
    const { history } = props;
    const { user, caseTitle, downloadLink } = useAppState();

    const [showVideo, setShowVideo] = useState(false);
    const [videoList, setVideoList] = useState(videos);
    const [clickedVideoId, setclickedVideoId] = useState(1);

    const [showBadgeList, setShowBadgeList] = useState(false);
    const [badgeList, setBadgeList] = useState(badges);
    const badgesRef = badgeList.map(() => useRef<HTMLLIElement>(null));

    const handlePlayVideo = (id: number) => {
        setShowVideo(true);
        setclickedVideoId(id);
    };

    useEffect(() => {
        if (!user || !user.uid) {
            return;
        }

        if (videos.length > 0) {
            return;
        }

        const db = firebase.firestore();
        const videoRef = db.collection(`users/${user.uid}/groups/MCI2021MCI2021/messages`);

        videoRef.get().then((res) => {
            const docs = res.docs;

            docs.map((videoData) => {
                const { id, video_url, created } = videoData.data();
                const createdDate = moment(created.toDate()).format('YYYY/MM/DD HH : MM');

                videos.push({ id, video_url, created, createdDate });
            });

            videos.sort((a, b) => {
                const aDate = moment(a.created.toDate());
                const bDate = moment(b.created.toDate());
                return aDate > bDate ? -1 : 1;
            });

            setVideoList([...videos]);
        });
    }, [user]);

    const SHOW = 'show';
    const HIDDEN = 'hidden';

    const handleBadgeEnter = (e: any) => {
        const { classList, parentElement } = e.target;

        parentElement.classList.remove(HIDDEN);
        if (!classList.contains('badge__front')) {
            return;
        }
        parentElement.classList.add(SHOW);
    };

    const handleBadgeLeave = (e: any) => {
        const { classList } = e.target.parentElement;
        if (classList.contains(SHOW)) {
            classList.remove(SHOW);
            classList.add(HIDDEN);
        }
    };

    useEffect(() => {
        if (!user || !user.badges) {
            return;
        }

        const checkingBadges = (badgeName: string) => {
            badgeList.map((item: Badge) => {
                if (item.id === badgeName) {
                    item.have = true;
                }
                return item;
            });
            setBadgeList(badgeList);
        };

        user.badges.forEach((badge) => {
            checkingBadges(badge.key);
        });
    }, [user]);

    return (
        <div className="note__wrap">
            <div className={`note__video ${!videoList || videoList.length === 0 ? 'no__video__data' : ''}`}>
                <h2 className="note__title font_lab">수사수첩</h2>
                <p className="note__txt">단서를 기록한 수사 영상을 수사대 본부에 공유할 수 있습니다.</p>
                <div className="buttons">
                    <MciButton
                        className="btn_note_check_badge"
                        label="획득한 뱃지 확인하기"
                        color="black"
                        onClick={() => {
                            setShowBadgeList(true);
                        }}
                    />
                    <MciButton
                        className="btn_note_case"
                        label="사건 해결하기"
                        color="transparent_orange"
                        onClick={() => history.push('/pass')}
                    />
                </div>
                {videoList.length > 0 && (
                    <ul className="video__list">
                        {videoList.map((data: VideoProperties, idx) => {
                            return (
                                <li
                                    className="video__box"
                                    key={`${idx}_${data.id}`}
                                    onClick={() => handlePlayVideo(idx)}
                                    ref={badgesRef[idx]}
                                >
                                    <div className="video">
                                        <video preload="metadata" src={`${data.video_url}#t=0.5`}></video>
                                    </div>
                                    <span className="created__date">{data.createdDate}</span>
                                </li>
                            );
                        })}
                    </ul>
                )}
                {(!videoList || videoList.length === 0) && (
                    <p className="no__video__data__txt">
                        수사관님 중요한 단서는 영상으로 기록해서 보관하세요 : )<br />
                        성공적인 수사를 위해 단서 기록은 필수입니다!
                    </p>
                )}
            </div>
            <div className="note__badge">
                <h2 className="note__title font_lab">나의 뱃지 목록</h2>
                <ul className="badge__list">
                    {badgeList.map((badge: Badge) => {
                        return (
                            <li
                                className="badge"
                                key={badge.id}
                                onMouseEnter={(e) => {
                                    handleBadgeEnter(e);
                                }}
                                onMouseLeave={handleBadgeLeave}
                            >
                                <div className="badge__wrap">
                                    <div className="badge__front">
                                        <i className="badge__icon" style={setBackgroundImg(badge.icon)} />
                                        <strong>{badge.name}</strong>
                                    </div>
                                    <div className="badge__back">
                                        <p>{badge.explanation}</p>
                                        <MciButton
                                            className="btn_view_pass"
                                            label="출입증 보기"
                                            color="transparent_orange"
                                            onClick={() => {
                                                setShowBadgeList(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                {!badge.have && (
                                    <div className="does-not-have">
                                        <span className="icon__lock" />
                                    </div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
            {videoList && videoList.length > 0 && (
                <PopUpVideo isView={showVideo} setView={setShowVideo} data={videoList[clickedVideoId]} />
            )}
            <PassSlide isView={showBadgeList} setView={setShowBadgeList} data={badgeList} />
        </div>
    );
};

const setBackgroundImg = (img: string) => {
    return { backgroundImage: `url(${img})` };
};

export default InvestigationNotes;
