import { useEffect, useState } from 'react';
import { RouterWithLocation } from '../App';
import ApplicationPage from '../components/application/ApplicationPage';
import AlertMessageForm from '../components/common/alert-message/AlertMessageForm';
import { resetLocation } from '../utils/redirectUtils';

const Application = (props: RouterWithLocation) => {
    const { location } = props;
    const [locationMessage, setLocationMessage] = useState('');

    useEffect(() => {
        if (!location.state || !location.state.message) {
            setLocationMessage('');
            return;
        }

        setLocationMessage(location.state.message);
        resetLocation(props);
    }, []);

    const handleAlertClose = () => {
        resetLocation(props);
        setLocationMessage('');
    };

    return (
        <main className="mci_application bg_black_img">
            <AlertMessageForm alertType={locationMessage} onClose={handleAlertClose} />
            <ApplicationPage />
        </main>
    );
};

export default Application;
